import { render, staticRenderFns } from "./ObentoMenu.vue?vue&type=template&id=dd07015e&scoped=true&"
import script from "./ObentoMenu.vue?vue&type=script&lang=ts&"
export * from "./ObentoMenu.vue?vue&type=script&lang=ts&"
import style0 from "./ObentoMenu.vue?vue&type=style&index=0&id=dd07015e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd07015e",
  null
  
)

export default component.exports