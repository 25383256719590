import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    resolution: {
      x: 0,
      y: 0,
    },
  },
  getters: {
    isMobile: (state) => {
      return state.resolution.x < 768
    },
  },
  mutations: {
    resize: (state) => {
      state.resolution.x = document.body.clientWidth
      state.resolution.y = window.innerHeight
    },
  },
  actions: {},
  modules: {},
})
